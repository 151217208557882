/* LoadingPage.css */
.gradient-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;   /* Full viewport width */
    height: 100vh;  /* Full viewport height */
    background: linear-gradient(135deg, #02033c, #040005); /* Vertical gradient */
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
  }

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:40%; /* Set a max width for the container */
    height:60%;
    margin: 0 auto;
    padding: 20px;
    background-color: #2d2d2d43;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
  }
  
  .loading-tasks {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .loading-tasks p {
    font-size: 18px;
    margin: 5px 0;
    color: #313131;
    transition: color 0.3s, font-weight 0.3s;
  }
  
  .loading-tasks p.active-task {
    font-weight: bold;
    color: #f4e3e3;
  }
  
  .progress-bar {
    width: 100%;
    max-width: 400px; /* Set a max width for the progress bar */
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .progress {
    height: 100%;
    background-color: #02639a9c;
    transition: width 0.5s;
  }
  
  .summary-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .summary-container h2 {
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .summary-container p {
    margin: 5px 0;
    color: #ffffff;
  }
  
  .summary-container button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #02639a9c;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .summary-container button:hover {
    background-color: #c77676;
  }
  