.upload-container {
    display: flex;
    min-height: 100vh;
    background: linear-gradient(90deg, #121c43, black, black);
    color: #ffffff;
    font-family: Arial, sans-serif;
}

.left-panel, .right-panel {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.left-panel {
    max-width: 50%;
}

.right-panel {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.sample-info {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 2rem;
}

.file-drop-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
}

.file-drop-area:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.file-input-label {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-input-label:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.nav-button {
    align-self: flex-start;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.next-button {
    align-self: stretch; /* Make the button as wide as the parent container */
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
    text-align: center; 
}

.next-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .transition-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 1000; /* High z-index to ensure it covers everything else */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  



  
  /* Button container to align buttons properly */
.button-container {
    display: flex;
    justify-content: space-between; /* Space out the buttons */
    align-items: center; /* Align items vertically centered */
    margin-top: 1rem; /* Space above the buttons */
}

/* Style for buttons to ensure they are aligned properly */
.next-button, .nav-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.nav-button {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    /* Align the button to the start */
    margin-right: auto;
}

.nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.next-button {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    /* Align the button to the end */
    margin-left: auto;
    text-align: center;
}

.next-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
