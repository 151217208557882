.tree-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: radial-gradient(circle,black,black,black,black, #06091d);
    padding: 2rem;
    box-sizing: border-box;
}

.header {
    color: #ffffff;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.video-viewer {
    width: 80vw; /* Adjust width as needed */
    height: auto; /* Height will adjust based on the video's aspect ratio */
    max-width: 1200px; /* Set a maximum width for the container */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000; /* Set background color for the video container */
    overflow: hidden; /* Ensure video is contained within the borders */
    position: relative;
}

.video-viewer video, .video-viewer img {
    max-width: 100%;
    max-height: 70vh; /* Ensures the video fits within the view height */
    object-fit: contain; /* Ensures the entire video is visible without cropping */
}

.control-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.control-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
