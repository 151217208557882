/* MainPage.css */
.main-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: -0px;
    height: 100vh;
    padding: 0px;
    background: linear-gradient(135deg, #020465, #040005);
    color: #ffffff;
    font-family: Arial, sans-serif;
    font-size: 18px;
    overflow: hidden;
    
  }
  
  .top-left, .bottom-left, .top-right, .bottom-right {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin:20px
  }
  
  .top-left {
    grid-column: 1;
    grid-row: 1;
    margin-left:35px;
    margin-top:35px;
  }
  
  .bottom-left {
    grid-column: 1;
    grid-row: 2;
    font-size: 20px;
    margin-left:35px;
    margin-bottom:35px;
  }
  
  .top-right {
    grid-column: 2;
    grid-row: 1;
    overflow: auto;
    /* font-size: 14px; */
    margin-right:35px;
    margin-top:35px;
  }
  
  .bottom-right {
    grid-column: 2;
    grid-row: 2;
    font-size: 18px;
    overflow: auto;
    margin-right:35px;
    margin-bottom:35px;
  }
  
  .protein-info {
    margin-bottom: 20px;
  }
  
  .protein-model {
    flex-grow: 1;
    position: relative;
    border-radius: 10px;
    border: solid 6px rgb(245, 225, 186);
  }
  
  .bottom-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  button {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 25px;
  }
  
  button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  /* MainPage.css */
button:disabled {
    background-color: #1a191b64;
    color: #666;
    cursor: not-allowed;
  }
  
  
  h3 {
    margin-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 10px;
  }



  .biotech-summary {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stats-section {
    margin-top: 20px;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .stat-group {
    background-color: #00ffea27;
    padding: 10px;
    border-radius: 5px;
  }
  
  .stat {
    margin: 5px 0;
  }

  .site-info table {
    width: 100%; /* Full width of its container */
    border-collapse: collapse; /* Ensures borders between cells are merged */
  }
  
  .site-info th, .site-info td {
    text-align: left; /* Aligns text to the left */
    padding: 8px; /* Adds space around text in cells */
    border-bottom: 1px solid #ccc; /* Light grey border below each row */
  }
  
  .site-info th {
    background-color: #96838328; /* Light grey background for headers */
    color: #ffffff; /* Dark grey text color for headers */
  }
  
  .site-info tbody tr:hover {
    background-color: #e8f4ff21; /* Light blue background on row hover */
  }
  