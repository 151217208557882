.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #0f183d, #000000, black);
    padding: 2rem;
    box-sizing: border-box;
}

.header {
    margin-bottom: 2rem;
    text-align: center;
}

.header-image {
    max-width: 350px;
    height: auto;
    margin-bottom: -6rem;
}

.input-group {
    width: 100%;
    max-width: 400px;
    margin-bottom: 1.5rem;
}

.label {
    display: block;
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.input {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #3f427289;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.button-text {
    margin-right: 0.5rem;
}

.button-icon {
    transition: transform 0.3s ease;
}

.button:hover .button-icon {
    transform: translateX(5px);
}

@keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}